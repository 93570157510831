'use client';

import React, { Fragment, ReactNode, useState } from 'react';

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@shadcn/components/ui/dialog';
import { useTranslations } from 'next-intl';
import { LoginForm } from '../Forms/LoginForm.jsx';
import { RegisterForm } from '../Forms/RegisterForm.jsx';
import { RecoverPasswordForm } from '../Forms/RecoverPasswordForm.jsx';

type AuthType = 'login' | 'register' | 'recoverPassword';
export const AuthModal: React.FC<{
	authType?: AuthType;
	trigger?: ReactNode;
	defaultOpen?: boolean;
	afterSubmit?: () => unknown;
}> = ({ authType = 'login', trigger, afterSubmit, defaultOpen }) => {
	const t = useTranslations('Auth');
	const [currentAuthType, setAuthType] = useState<AuthType>(authType);
	const [open, setOpen] = React.useState(defaultOpen || false);
	const title = {
		login: t('login'),
		register: t('register'),
		recoverPassword: t('recover-password'),
	};

	const onOpenChange = isOpen => {
		setOpen(isOpen);

		if (!isOpen && currentAuthType !== 'login') {
			setAuthType('login');
		}
	};

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogTrigger className="hover:text-gold" asChild={Boolean(trigger)}>
				{trigger ? trigger : t('login')}
			</DialogTrigger>
			<DialogContent
				className="top-0 h-screen rounded-none p-8  lg:top-12  lg:h-fit lg:rounded-3xl lg:p-16"
				onInteractOutside={e => {
					// disable closing modal if overlay is clicked
					e.preventDefault();
				}}
			>
				{currentAuthType !== 'login' && (
					<a
						className="mb-10 font-semibold text-brand-navy"
						href=""
						onClick={e => {
							e.preventDefault();
							setAuthType('login');
						}}
					>
						{`< `} {t('login')}
					</a>
				)}
				<DialogHeader>
					<DialogTitle className="mb-10 text-4xl font-light text-muted-navy">
						{title[currentAuthType]}
					</DialogTitle>
				</DialogHeader>
				{currentAuthType === 'login' ? (
					<LoginForm
						afterSubmit={() => {
							setOpen(false);
							if (afterSubmit) {
								afterSubmit();
							}
						}}
					/>
				) : currentAuthType === 'register' ? (
					<RegisterForm
						afterSubmit={() => {
							setOpen(false);
							if (afterSubmit) {
								afterSubmit();
							}
						}}
					/>
				) : (
					<RecoverPasswordForm
						afterSubmit={() => {
							setOpen(false);
							if (afterSubmit) {
								afterSubmit();
							}
						}}
					/>
				)}

				{currentAuthType === 'login' && (
					<Fragment>
						<div className="flex justify-center">
							<p className="mr-3">{t('forgotPassword')}</p>
							<a
								className="font-semibold text-brand-navy"
								href=""
								onClick={e => {
									e.preventDefault();
									setAuthType('recoverPassword');
								}}
							>
								{t('recover')}
							</a>
						</div>
						<div className="flex justify-center rounded-3xl bg-gold-transparent p-5">
							<p className="mr-3">{t('no-user')}</p>
							<a
								className="font-semibold text-brand-navy"
								href=""
								onClick={e => {
									e.preventDefault();
									setAuthType('register');
								}}
							>
								{t('register')}
							</a>
						</div>
					</Fragment>
				)}
			</DialogContent>
		</Dialog>
	);
};
